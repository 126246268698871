@import url('https://fonts.googleapis.com/css2?family=Amaranth&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amaranth&family=Roboto&display=swap');

body{
    -webkit-font-smoothing: antialiased;
}


/*
    background-color: rgba(0, 0, 0, 0.6);
   */
.slayder{
    color: white;
    font-size: 18px;
    height: 90vh;
    margin: 0 auto;
    z-index: 0;
    text-shadow: 2px 2px 0px rgba(0,0,0,0.5);

}

/*        display: flex;
    text-align: left;
    vertical-align: baseline;
        width: 100%;
    align-content: center;
    align-items: center;
    color: white;
        box-sizing: border-box;
    top:45vh;

    */
.card-text{
    overflow: hidden;
    position: absolute;
    top: 35vh;
    left:5vw;
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    line-height: 1.6;
    text-shadow: 2px 2px 0px rgba(0,0,0,0.5);
    box-sizing: border-box;

}

.elementor-heading-title {
    overflow: hidden;
    font-family: 'Amaranth', sans-serif;
    font-size: 1.4em;
    color: white;
    margin-top:0px;
    text-shadow: 2px 2px 0px rgba(0,0,0,0.5);
    box-sizing: border-box;

}

.wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1,1fr);
    grid-gap: 15px;
    grid-auto-rows: 100px;
    grid-template-areas:
            "a"
            "a"
            "a"
            "a"
            "c";
}


.item1 {
    grid-area: a;
}

.item2 {
    font-family: 'Amaranth', sans-serif;
    font-size: 2em;
    color: white;
    margin-top:3px;
    grid-area: a;
}

.item3 {
    grid-area: c;
    align-self: start;
    font-family: 'Roboto', sans-serif;
    font-size: 2vh;
    color:white;
    line-height: 1.6;
}