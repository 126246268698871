@import url(https://fonts.googleapis.com/css2?family=Amaranth&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amaranth&family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading-title {
    /*font-family: Playfair Display, Sans-serif, serif;*/
    font-size: 41px;
    color: white;
}

div{
    line-height: 1.5;
    font-size: 18px;
}

.zagolov {
    /*font-family: "Allerta", Sans-serif;*/
    margin-top: 5px;
    font-size: 24px;
    color: white;
    text-align: center;
    font-weight: bold;
}

.row_brown{
    /*background: #2B1A09;*/
    background: black;
    color: white;
}

.row_white{
    background: white;
    color: black;
}

.tab_text1{
    text-indent: 20px;
}


.row_brown A:visited {
    color: white; /* Цвет посещенной ссылки */
}

.row_white A:visited {
    color: black; /* Цвет посещенной ссылки */
}

.nav {
    /*background-color: var(--off-white);*/
    /*background-color: #333;*/
    background-color: black;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    font-size: 18px;
    color:white;
    /*width: 100%;
    height: 80px;
    z-index: 1000;*/
    /*box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);*/
}

.dropdown-menu.show {
    background-color: black;
    border: 1px solid rgba(255,255,255);
    margin-left: 0;
}

.dropdown-menu {
    margin: auto;
}

.dropdown-menu.show  a:hover{
    background-color: gray;
    color:black;
}

#practica{
    color: white;
    display: block;
    margin: auto;
    background-color: black;
}

.nav-logo:hover {
    cursor: pointer;
}

.nav .nav-content {
    max-width: 900px;
    padding: 0rem 3rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color:white;
}
.nav-item a {
    color: white;
}

.dropdown-toggle{
    color: darkgoldenrod;

}
.nav-item {
    text-align: center;
    display: inline;
    margin-left: 2rem;
    color: white;
}

.nav-item:hover {
    color: white;
    cursor: pointer;
}

.msactive {
    border-bottom: 1px solid white;
    color: white;
}

toggler:hover {
    -webkit-text-decoration: white;
            text-decoration: white;
}

.navbar-toggler {
    color: darkgoldenrod;
    border-color: darkgoldenrod;

    border: 1px solid darkgoldenrod;
    border-radius: .25rem;
}




.home{
    background: black;
    font-family: "Playfair Display", Sans-serif;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;

    z-index: -1000;
}
.img_title {
    max-width: 100%;
    height: auto;
}


.about{
    background: black;
    color: white;
    min-height: 800px;
    font-size: 18px;
}



.uslugi{
    background: white;
    color: black;
}

.uslugi .row{
    margin: auto;
    text-align: center;
}
.heading-title {
    font-size: 41px;
    font-color: #000;
}


.zagolov {
    font-size: 24px;
    font-color: black;
}


.prof1{
    text-align: center;
    background: white;
    font-size: 16px;
}

.prof2{
    text-align: center;
    background: white;
    font-size: 15px;
}

.body{
    background: white;
    text-align: center;
    font-size: 14px;
}

.name{
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    background: white;
}


.backTitle{
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    background: white;
}

.backText{

    text-align: justify;
    background: white;
    line-height: 1.5;
}
.flip-card {
    cursor: pointer;

    /*border: 1px solid #f1f1f1;*/

    perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-card-inner:hover{
    box-shadow: 0px 0px 15px 1px #525252;
}
/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    transition: transform 1.1s;
    box-shadow: 0px 0px 5px 1px #525252;
    transform-style: preserve-3d;
}

 /*Do an horizontal flip when you move the mouse over the flip box container */
/*.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}*/

/* Position the front and back side */
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    background: transparent;

}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    text-align: center;
    color: black;
    width: 100%;
    height: 100%;
    background: white;
}
/* Style the back side
    position:relative;
    */
.flip-card-back {
    padding: 14px;
    color: black;
    transform: rotateY(180deg);
    overflow: auto;
    background: white;
}
/*
background:darkgoldenrod;
  position:absolute;
      border:none;
    vertical-align:bottom;
    display:inline-block;
 */
.flip-card-link{
    bottom: 0;
    text-align: center;
    color:blue;
    vertical-align: text-bottom;

}

.practica{
    background: black;
    color: white;
    min-height: 700px;
    font-size: 18px;
}


.team{
    background: white;
    color: black;
    min-height: 700px;
}

/*.row_white_tieam{
    background: white;
    color: black;
    font-size: 20px;
}*/

.kontact{
    background: black;
    color: darkgoldenrod;
    min-height: 700px;
    text-align: center;

}

.pravila{
    background: black;
    color: darkgoldenrod;
    min-height: 700px;
}

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;

    src: local('Playfair Display'),local('PlayfairDisplay'),
        url(/static/media/PlayfairDisplay-Black.6b2baf9d.ttf) format('truetype'), 
        url(/static/media/PlayfairDisplay-BlackItalic.8e4c7266.ttf) format('truetype'), 
        url(/static/media/PlayfairDisplay-Bold.2cc98ebe.ttf) format('truetype'),
        url(/static/media/PlayfairDisplay-BoldItalic.15660f05.ttf) format('truetype'),
        url(/static/media/PlayfairDisplay-ExtraBold.75ee27eb.ttf) format('truetype'),
        url(/static/media/PlayfairDisplay-ExtraBoldItalic.a2106aaa.ttf) format('truetype'),
        url(/static/media/PlayfairDisplay-Italic.8969f241.ttf) format('truetype'),
        url(/static/media/PlayfairDisplay-Medium.6b6f0053.ttf) format('truetype'), 
        url(/static/media/PlayfairDisplay-MediumItalic.ecd99b74.ttf) format('truetype'),
        url(/static/media/PlayfairDisplay-Regular.b3721ba3.ttf) format('truetype'),
        url(/static/media/PlayfairDisplay-SemiBold.6165164d.ttf) format('truetype'),
        url(/static/media/PlayfairDisplay-SemiBoldItalic.9e8866b2.ttf) format('truetype'),
}
body {
    font-family: 'Playfair Display';
}

body{
    -webkit-font-smoothing: antialiased;
}


/*
    background-color: rgba(0, 0, 0, 0.6);
   */
.slayder{
    color: white;
    font-size: 18px;
    height: 90vh;
    margin: 0 auto;
    z-index: 0;
    text-shadow: 2px 2px 0px rgba(0,0,0,0.5);

}

/*        display: flex;
    text-align: left;
    vertical-align: baseline;
        width: 100%;
    align-content: center;
    align-items: center;
    color: white;
        box-sizing: border-box;
    top:45vh;

    */
.card-text{
    overflow: hidden;
    position: absolute;
    top: 35vh;
    left:5vw;
    font-family: 'Roboto', sans-serif;
    font-size: 1em;
    line-height: 1.6;
    text-shadow: 2px 2px 0px rgba(0,0,0,0.5);
    box-sizing: border-box;

}

.elementor-heading-title {
    overflow: hidden;
    font-family: 'Amaranth', sans-serif;
    font-size: 1.4em;
    color: white;
    margin-top:0px;
    text-shadow: 2px 2px 0px rgba(0,0,0,0.5);
    box-sizing: border-box;

}

.wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1,1fr);
    grid-gap: 15px;
    grid-auto-rows: 100px;
    grid-template-areas:
            "a"
            "a"
            "a"
            "a"
            "c";
}


.item1 {
    grid-area: a;
}

.item2 {
    font-family: 'Amaranth', sans-serif;
    font-size: 2em;
    color: white;
    margin-top:3px;
    grid-area: a;
}

.item3 {
    grid-area: c;
    align-self: start;
    font-family: 'Roboto', sans-serif;
    font-size: 2vh;
    color:white;
    line-height: 1.6;
}
