.nav {
    /*background-color: var(--off-white);*/
    /*background-color: #333;*/
    background-color: black;
    position: sticky;
    top: 0px;
    font-size: 18px;
    color:white;
    /*width: 100%;
    height: 80px;
    z-index: 1000;*/
    /*box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);*/
}

.dropdown-menu.show {
    background-color: black;
    border: 1px solid rgba(255,255,255);
    margin-left: 0;
}

.dropdown-menu {
    margin: auto;
}

.dropdown-menu.show  a:hover{
    background-color: gray;
    color:black;
}

#practica{
    color: white;
    display: block;
    margin: auto;
    background-color: black;
}

.nav-logo:hover {
    cursor: pointer;
}

.nav .nav-content {
    max-width: 900px;
    padding: 0rem 3rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color:white;
}
.nav-item a {
    color: white;
}

.dropdown-toggle{
    color: darkgoldenrod;

}
.nav-item {
    text-align: center;
    display: inline;
    margin-left: 2rem;
    color: white;
}

.nav-item:hover {
    color: white;
    cursor: pointer;
}

.msactive {
    border-bottom: 1px solid white;
    color: white;
}

toggler:hover {
    text-decoration: white;
}

.navbar-toggler {
    color: darkgoldenrod;
    border-color: darkgoldenrod;

    border: 1px solid darkgoldenrod;
    border-radius: .25rem;
}

