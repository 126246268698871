.team{
    background: white;
    color: black;
    min-height: 700px;
}

/*.row_white_tieam{
    background: white;
    color: black;
    font-size: 20px;
}*/
