.uslugi{
    background: white;
    color: black;
}

.uslugi .row{
    margin: auto;
    text-align: center;
}
.heading-title {
    font-size: 41px;
    font-color: #000;
}


.zagolov {
    font-size: 24px;
    font-color: black;
}

