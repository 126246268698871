.heading-title {
    /*font-family: Playfair Display, Sans-serif, serif;*/
    font-size: 41px;
    color: white;
}

div{
    line-height: 1.5;
    font-size: 18px;
}

.zagolov {
    /*font-family: "Allerta", Sans-serif;*/
    margin-top: 5px;
    font-size: 24px;
    color: white;
    text-align: center;
    font-weight: bold;
}

.row_brown{
    /*background: #2B1A09;*/
    background: black;
    color: white;
}

.row_white{
    background: white;
    color: black;
}

.tab_text1{
    text-indent: 20px;
}


.row_brown A:visited {
    color: white; /* Цвет посещенной ссылки */
}

.row_white A:visited {
    color: black; /* Цвет посещенной ссылки */
}
